import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{
      maxWidth: `300px`,
      margin: `auto`,
    }}>
      <Image />
    </div>
    <div style={{
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }}>
      <h1>Zodiactive App</h1>
    </div>
    <p>Animal and starsign lookup.</p>
    <p>
      <Link to="/privacy">Privacy policy.</Link>
    </p>
  </Layout>
)

export default IndexPage
